import {useParams} from "react-router-dom"
import RouteContainer from "../../../common/styles/RouteContainer"
import RowSpacer from "../../../common/styles/RowSpacer"
import useBookUsedOffline from './hooks/useBookUsedOffline';
import useUrlQuery from '../../../common/hooks/useUrlQuery';
import React, {useEffect} from 'react';
import {BooksitoutServer} from '../../../config/BooksitoutServer';
import ApiUrls from '../../../ApiUrls';
import useLoginStore from '../../login/useLoginStore';
import searchCache from '../searchBar/searchCache';
import IndexSearchBar from '../../index/card/IndexSearchBar';
import BookIsbnCard from "../../book/isbn/BookIsbnCard";
import useBookIsbn from "../../book/isbn/useBookIsbn";
import BookSourceOfflineLibrarySection from "./section/BookSourceOfflineLibrarySection";
import BookSourceOnlineLibrarySection from "./section/BookSourceOnlineLibrarySection";
import BookSourceSubscriptionSection from "./section/BookSourceSubscriptionSection";
import BookSourceOnlineUsedSection from "./section/BookSourceOnlineUsedSection";
import BookSourceOfflineUsedSection from "./section/BookSourceOfflineUsedSection";

const SearchBookSourceRoute = () => {
    const {isbn13} = useParams()
    const query = useUrlQuery('q')

    const [bookIsbn, isLoading] = useBookIsbn(isbn13 ?? null)

    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())
    useEffect(() => {
        if (!isLoggedIn) {
            searchCache.updateCache(
                'search-book-histories',
                query?.toString() ?? '',
                `/search/${isbn13}?q=${query}`
            )
        } else if (isbn13 != null && query != null) {
            const body = {
                isbn: isbn13,
                query: query
            }

            BooksitoutServer
                .post(ApiUrls.Search.BookHistory.POST, body)
                .then(r => {})
        }
    }, [isLoggedIn, isbn13, query])

    if (isLoading || bookIsbn == null) {
        return <></>
    }

    return (
        <RouteContainer className='pb-5'>
            <RowSpacer/>
            <IndexSearchBar/>

            <RowSpacer/>
            <BookIsbnCard book={bookIsbn}/>

            <RowSpacer/>
            <BookSourceOfflineLibrarySection/>

            <RowSpacer/>
            <BookSourceOnlineLibrarySection/>

            <RowSpacer/>
            <BookSourceSubscriptionSection/>

            <RowSpacer/>
            <BookSourceOnlineUsedSection/>

            <RowSpacer/>
            <BookSourceOfflineUsedSection/>
        </RouteContainer>
    )
}

export default SearchBookSourceRoute