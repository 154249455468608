import React from 'react'
import styled from "styled-components";
import useBookUsedOnline from "../hooks/useBookUsedOnline";
import {useParams} from "react-router-dom";
import useUrlQuery from "../../../../common/hooks/useUrlQuery";
import BookSourceSection from "./common/BookSourceSection";
import RowSpacer from "../../../../common/styles/RowSpacer";
import ContentContainer from "../../../../common/styles/ContentContainer";
import {getBookSourceIcon, getBookSourceName} from "../BookSource";
import ColSpacer from "../../../../common/styles/ColSpacer";
import {SearchBookSourceUsedOnlineResponse} from "../SearchBookSourceUsedResponse";
import utils from "../../../../common/utils";
import BookSourceNoContent from "../BookSourceNoContent";

const BookSourceOnlineUsedSection = () => {
    const {isbn13} = useParams()
    const query = useUrlQuery('q')
    const [usedOnline, isUsedOnlineLoading] = useBookUsedOnline(isbn13 ?? '', query ?? '')

    return (
        <BookSourceSection
            title={`중고 (온라인)`}
            body={
            isUsedOnlineLoading ?
                <></>
                :
                <Body onlineUsed={usedOnline}/>
            }
        />
    )
}

interface BodyProps {
    onlineUsed: SearchBookSourceUsedOnlineResponse[]
}

const Body: React.FC<BodyProps> = ({onlineUsed}) => {
    if (onlineUsed.length === 0) {
        return <BookSourceNoContent message={'중고 (온라인)에는 책이 없어요'}/>
    }

    return (
        <>
            {
                onlineUsed.map(used => {
                    return (
                        <div className={'hover-effect'}>
                            <a href={used.link!!} target={'_blank'}>
                                <RowSpacer size={10}/>
                                <ContentContainer>
                                    <div>
                                        <span className={'d-flex'}>
                                            <Icon src={getBookSourceIcon(used.provider)}/>

                                            <ColSpacer size={5}/>

                                            <div>
                                                <h6 className={'p-0 m-0 pt-1'}>{getBookSourceName(used.provider)}</h6>
                                                <Info>최저 {utils.insertCommas(used.minPrice)}원</Info>
                                                <Info>총 {used.stockCount}권</Info>
                                            </div>
                                        </span>
                                    </div>
                                </ContentContainer>
                            </a>
                        </div>
                    )
                })
            }
        </>
    );
}

const Icon = styled.img.attrs({
    className: 'rounded'
})`
    width: 30px;
    height: 30px;
`

const Info = styled.h6.attrs({
    className: 'text-secondary'
})`
    font-size: 0.9rem;
    padding: 0 0 0 20px;
    margin: 0;
`

export default BookSourceOnlineUsedSection
