import React from 'react'
import styled from 'styled-components';
import {useParams} from "react-router";
import RouteContainer from '../../../common/styles/RouteContainer';
import {Card} from "react-bootstrap";
import useBookIsbn from "./useBookIsbn";
import NoContent from "../../../common/NoContent";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import BookIsbnCard from "./BookIsbnCard";

const BookIsbnRoute = () => {
    const {isbn} = useParams()
    const [book, isLoading, isError] = useBookIsbn(isbn ?? null)

    if (isLoading) {
        return <></>
    }

    if (book == null || isError) {
        return (
            <NoContent message={`찾으시는 책이 없어요<br/>(isbn = ${isbn})`}/>
        )
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <BookIsbnCard book={book}/>

            <RowSpacer/>
            <Card>
                <CardBodyContentContainer height={400}>
                    <Container>
                        <Title>소개</Title>
                        {book.description}
                    </Container>
                </CardBodyContentContainer>
            </Card>
            <RowSpacer/>
        </RouteContainer>
    )
}

const Container = styled.div.attrs({
    className: 'row'
})`
    height: 100%;
    padding: 20px;
`

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0;
    margin-bottom: 15px;
`

const Info = styled.span.attrs({
    className: 'text-book'
})`
    font-weight: bold;
`

export default BookIsbnRoute
