import React from 'react'
import styled from 'styled-components';
import RouteContainer from "../../../common/styles/RouteContainer";
import useAppUserProfile from "../useAppUserProfile";
import {useParams} from "react-router";
import {Button, Card} from 'react-bootstrap';
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import BookMineCard from "../../book/mine/BookMineCard";
import CardTitle from '../../../common/styles/CardTitle';
import booksitoutIcon from '../../../config/BooksitoutIcon';
import NoContent from "../../../common/NoContent";
import ProfileUserCard from "./ProfileUserCard";
import AddButton from "../../../common/button/AddButton";
import useHtmlTitle from "../../../common/useHtmlTitle";

const ProfileRoute = () => {
    const {userId} = useParams()
    const [appUserWithBooks, isLoading, isError] = useAppUserProfile(userId, 0, 12)

    useHtmlTitle(`${appUserWithBooks?.user.name ?? '?'} | 프로필`)

    if (isLoading) {
        return <></>
    }

    if (isError || appUserWithBooks == null) {
        return <NoContent message={'유저가 없거나 탈퇴한 유저에요.'}/>
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <ProfileUserCard user={appUserWithBooks.user}/>

            <RowSpacer/>
            <a href={`/users/${userId}/books?page=1`}>
                <Card>
                    <CardBodyContentContainer>
                        <CardTitle icon={<booksitoutIcon.book/>} title={'공유 설정한 책'} url={``}/>

                        <Row>
                            {
                                appUserWithBooks.books.length == 0 ?
                                    <>
                                        <RowSpacer size={70}/>
                                        <NoContent message={`공유 설정한 책이 없어요`}/>
                                        <RowSpacer size={70}/>
                                    </>

                                    :
                                    appUserWithBooks.books.map(book =>
                                        <Col>
                                            <BookMineCard book={book} url={`/users/${userId}/books/${book.id}`}/>
                                        </Col>
                                    )
                            }
                        </Row>

                        <RowSpacer size={10}/>

                        <ButtonRow>
                            <ButtonCol>
                                <Button variant={'outline-book'} className={'w-100'}>
                                    전체보기
                                </Button>
                            </ButtonCol>
                        </ButtonRow>
                    </CardBodyContentContainer>
                </Card>
            </a>

            <RowSpacer/>
        </RouteContainer>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})``

const Col = styled.div.attrs({
    className: 'col-6 col-md-4 col-lg-3 col-xl-2'
})``

const ButtonRow = styled.div.attrs({
    className: 'row'
})`
    justify-content: center;
`

const ButtonCol = styled.div.attrs({
    className: 'col-12 col-md-6'
})``

export default ProfileRoute
