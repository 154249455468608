import BooksitoutImages from "../../../images/BooksitoutImages";

type BookSource =
	| 'LIBRARY_ONLINE_SEOUL'
	| 'LIBRARY_ONLINE_SEOUL_EDUCATION'
	| 'LIBRARY_ONLINE_NATIONAL_ASSEMBLY'
	| 'LIBRARY_ONLINE_GYEONGGI_EDUCATION'
	| 'LIBRARY_ONLINE_GWANGHWAMUN'
	| 'LIBRARY_ONLINE_SEOUL_CONGRESS'

	| 'SUBSCRIPTION_MILLIE'
	| 'SUBSCRIPTION_RIDI'
	| 'SUBSCRIPTION_YES24'
	| 'SUBSCRIPTION_KYOBO'

    | 'USED_ONLINE_YES24'
	| 'USED_ONLINE_ALADIN'
	| 'USED_ONLINE_INTERPARK'
	| 'USED_ONLINE_KYOBO'
	
	| 'USED_OFFLINE_ALADIN'
	| 'USED_OFFLINE_YES24'
	
const getBookSourceIcon = (source: BookSource): string => {
	switch (source) {
		case 'LIBRARY_ONLINE_SEOUL':
			return BooksitoutImages.LibraryLogo.서울도서관
		case 'LIBRARY_ONLINE_SEOUL_EDUCATION':
			return BooksitoutImages.LibraryLogo.서울교육도서관
		case 'LIBRARY_ONLINE_NATIONAL_ASSEMBLY':
			return BooksitoutImages.LibraryLogo.국회도서관
		case 'LIBRARY_ONLINE_GYEONGGI_EDUCATION':
			return BooksitoutImages.LibraryLogo.경기교육도서관
		case 'LIBRARY_ONLINE_GWANGHWAMUN':
			return BooksitoutImages.LibraryLogo.광화문도서관
		case 'LIBRARY_ONLINE_SEOUL_CONGRESS':
			return BooksitoutImages.LibraryLogo.서울의회도서관
		case 'SUBSCRIPTION_MILLIE':
			return BooksitoutImages.WebsiteLogo.millie
		case 'USED_ONLINE_YES24':
		case 'USED_OFFLINE_YES24':
		case 'SUBSCRIPTION_YES24':
			return BooksitoutImages.WebsiteLogo.yes24
		case 'SUBSCRIPTION_RIDI':
			return BooksitoutImages.WebsiteLogo.ridi
		case 'SUBSCRIPTION_KYOBO':
		case 'USED_ONLINE_KYOBO':
			return BooksitoutImages.WebsiteLogo.kyobo
		case 'USED_ONLINE_ALADIN':
		case 'USED_OFFLINE_ALADIN':
			return BooksitoutImages.WebsiteLogo.aladin
		case 'USED_ONLINE_INTERPARK':
			return BooksitoutImages.WebsiteLogo.interpark
		default:
			return ''
	}
}

const getBookSourceName = (source: BookSource): string => {
	switch (source) {
		case "LIBRARY_ONLINE_SEOUL":
			return '서울 도서관'
		case "LIBRARY_ONLINE_SEOUL_EDUCATION":
			return '서울 교육 도서관'
		case "LIBRARY_ONLINE_NATIONAL_ASSEMBLY":
			return '국회 도서관'
		case "LIBRARY_ONLINE_GYEONGGI_EDUCATION":
			return '경기 교육 도서관'
		case "LIBRARY_ONLINE_GWANGHWAMUN":
			return '광화문 도서관'
		case "LIBRARY_ONLINE_SEOUL_CONGRESS":
			return '서울 의회 도서관'
		case "SUBSCRIPTION_MILLIE":
			return '밀리의 서재'
		case "SUBSCRIPTION_RIDI":
			return '리디'
		case "SUBSCRIPTION_YES24":
			return 'YES24'
		case "SUBSCRIPTION_KYOBO":
			return '교보문고'
		case "USED_ONLINE_YES24":
			return '교보문고'
		case "USED_ONLINE_ALADIN":
			return '알라딘'
		case "USED_ONLINE_INTERPARK":
			return '인터파크'
		case "USED_ONLINE_KYOBO":
			return '교보문고'
		case "USED_OFFLINE_ALADIN":
			return '알라딘'
		case "USED_OFFLINE_YES24":
			return 'YES24'
	}
}

export {getBookSourceIcon, getBookSourceName}
