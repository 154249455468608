import ApiUrls from "../../../../ApiUrls";
import styled from 'styled-components';
import SearchBar from "../../../search/searchBar/SearchBar";
import React, {useEffect, useState} from "react";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";
import SearchBookResponse from "../../../search/book/SearchBookResponse";
import toast from "react-hot-toast";
import SearchBookCard from "../../../search/book/SearchBookCard";
import RowSpacer from "../../../../common/styles/RowSpacer";
import AddBookSearchModal from "./AddBookSearchModal";
import breakpoints from "../../../../config/Breakpoints";
import useSearchQuery from "../../../../common/hooks/useSearchQuery";
import SearchBookCardLoading from "../../../search/book/SearchBookCardLoading";
import NoContent from "../../../../common/NoContent";
import {usePopularBooks} from "../../../community/popular-books/usePopularBooks";
import PopularBookResponse from "../../../community/popular-books/PopularBookResponse";
import CardBodyContentContainer from "../../../../common/styles/CardBodyContentContainer";

const AddBookSearchRoute = () => {
    const {query, setQuery, dQuery} = useSearchQuery('', 200)
    const [books, setBooks] = useState<SearchBookResponse[]>([])

    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
    const [selectedBook, setSelectedBook] = useState<SearchBookResponse | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isNoContent, setIsNoContent] = useState<boolean>(false)

    useEffect(() => {
        setIsLoading(dQuery !== '')

        if (dQuery !== '') {
            BooksitoutServer
                .get(ApiUrls.Search.Book.GET_NO_HISTORY(query))
                .then((res) => res.data)
                .then((data: SearchBookResponse[]) => {
                    setBooks(data)
                    setIsNoContent(data.length === 0)
                })
                .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
                .finally(() => setIsLoading(false))
        } else {
            setBooks([])
            setIsNoContent(false)
        }
    }, [dQuery])

    const openAddModal = (book: SearchBookResponse) => {
        setIsAddModalOpen(true)
        setSelectedBook(book)
    }

    return (
        <div>
            <AddBookSearchModal
                isAddModalOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                selectedBook={selectedBook}
            />

            <Center>
                <SearchBarContainer>
                    <SearchBar
                        placeholder={"추가할 책을 검색해 주세요"}
                        query={query}
                        queryHistories={[]}
                        querySuggestions={[]}
                        isShowingQueryHistory={false}
                        isShowingAutoComplete={false}
                        isFocusOnAppear={true}
                        setQuery={(q) => setQuery(q)}
                        setIsShowingQueryHistory={() => {
                        }}
                        handleSubmit={(e) => e.preventDefault()}
                    />
                </SearchBarContainer>
            </Center>

            <RowSpacer/>

            <Row>
                {
                    isNoContent ?
                        <NoContent message={'찾으시는 책이 없어요'}/>
                        :
                        isLoading ?
                            (
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(_ =>
                                    <Col>
                                        <SearchBookCardLoading/>
                                        <RowSpacer/>
                                    </Col>
                                )
                            )
                            :
                            books.length === 0 ?
                                <BestSellerList openAddModal={openAddModal}/>
                                :
                                (
                                    books.map(book =>
                                        <Col>
                                            <SearchBookCard book={book} onClick={() => openAddModal(book)}/>
                                            <RowSpacer/>
                                        </Col>
                                    )
                                )
                }
            </Row>
        </div>
    )
}

interface BestSellerListProps {
    openAddModal: (book: SearchBookResponse) => void
}

const BestSellerList: React.FC<BestSellerListProps> = ({openAddModal}) => {
    const size = 12

    const [isLoading, popularBooks] = usePopularBooks('BOOKSITOUT', size)

    return (
        <div className={'ps-md-5 pe-md-5'}>
            <RowSpacer/>
            <CardBodyContentContainer>
                <BestSellerListContainerTitle>베스트셀러에서 추가하기</BestSellerListContainerTitle>

                <RowSpacer size={10}/>
                <BestSellerListContainer>
                    {
                        isLoading ?
                            Array.from({length: size}).map(_ =>
                                <BestSellerListContainerItem>
                                    <SearchBookCardLoading/>
                                </BestSellerListContainerItem>
                            )
                            :
                            popularBooks.map(book =>
                                <BestSellerListContainerItem>
                                    <SearchBookCard
                                        book={convert(book)}
                                        onClick={() => openAddModal(convert(book))}
                                        height={100}
                                    />
                                    <RowSpacer size={5}/>
                                </BestSellerListContainerItem>
                            )
                    }
                </BestSellerListContainer>
            </CardBodyContentContainer>
            <RowSpacer/>
        </div>
    )

    function convert(popularBook: PopularBookResponse): SearchBookResponse {
        return {
            isbn13: popularBook.isbn,
            title: popularBook.title,
            subTitle: null,
            authors: popularBook.author,
            cover: popularBook.coverUrl ?? '',
            page: popularBook.page,
        }
    }
}

const BestSellerListContainer = styled.div.attrs({
    className: 'row'
})`
    justify-content: center;
`

const BestSellerListContainerItem = styled.div.attrs({
    className: 'col-6 col-sm-4 col-lg-3 col-xl-2 clickable'
})``

const BestSellerListContainerTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
`

const Center = styled.div`
    display: flex;
    justify-content: center;
`

const SearchBarContainer = styled.div`
    width: 50%;

    @media screen and (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;

const Row = styled.div.attrs({
    className: 'row'
})``

const Col = styled.div.attrs({
    className: 'col-6 col-md-3 col-xl-2'
})``

export default AddBookSearchRoute