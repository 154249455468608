import React from 'react'
import styled from 'styled-components';
import useBookDetailStore from "../../../book/detail/useBookDetailStore";
import IndexContentContainer from "../../../index/IndexContentContainer";
import RowSpacer from "../../../../common/styles/RowSpacer";
import BookPageBar from "../../../../common/BookPageBar";
import BookGiveUpButton from "../../../book/BookGiveUpButton";
import ColSpacer from "../../../../common/styles/ColSpacer";
import BookReadingSessionButton from "../../../book/reading/BookReadingSessionButton";

const ProfileBookDetailInfoCard = () => {
    const {book} = useBookDetailStore()

    if (book == null) {
        return <></>
    }

    return (
        <IndexContentContainer isNotHover={true}>
            <Container href={`${book.isbn == null ? `` : `/book/${book.isbn}`}`} isActive={book.isbn != null}>
                <CoverContainer>
                    <Cover src={book.cover} alt={book.title}/>
                </CoverContainer>

                <InfoContainer>
                    <div className="d-block d-md-none">
                        <RowSpacer/>
                    </div>

                    <Title>{book.title}</Title>
                    <Author>{book.author}</Author>

                    <RowSpacer/>

                    <BookPageContainer>
                        <BookPageBar book={book}/>
                    </BookPageContainer>
                </InfoContainer>
            </Container>
        </IndexContentContainer>
    );
}

interface ContainerProps {
    isActive: boolean
}

const Container = styled.a.attrs({
    className: 'row'
})<ContainerProps>`
    width: 100%;
    align-items: center;
    padding: 0;
    margin: 0;
    z-index: 0;
    
    pointer-events: ${props => props.isActive ? 'auto' : 'none'};
    
    &:hover {
        color: inherit;
    }
`;

const CoverContainer = styled.div.attrs({
    className: 'col-12 col-md-4'
})`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Cover = styled.img.attrs<{ src: any }>({
    className: 'rounded border'
})`
    max-height: 250px;
`;

const InfoContainer = styled.div.attrs({
    className: 'col-12 col-md-8'
})`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 1.2rem;
`;

const Author = styled.h2.attrs({
    className: 'text-secondary'
})`
    font-size: 1rem;
`;

const BookPageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
`

export default ProfileBookDetailInfoCard
