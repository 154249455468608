import React from 'react'
import styled from 'styled-components';
import BookSourceSection from "./common/BookSourceSection";
import useCurrentLocation from "../../../library/useCurrentLocation";
import useBookLibraryOffline from "../hooks/useBookLibraryOffline";
import {useParams} from "react-router-dom";
import {SearchBookSourceLibraryOfflineResponse} from "../SearchBookSourceLibraryResponse";
import ContentContainer from "../../../../common/styles/ContentContainer";
import RowSpacer from "../../../../common/styles/RowSpacer";
import LoanPossibleBadge from "./common/LoanPossibleBadge";
import ColSpacer from "../../../../common/styles/ColSpacer";
import NoContent from "../../../../common/NoContent";
import BookSourceNoContent from "../BookSourceNoContent";

const BookSourceOfflineLibrarySection = () => {
    const {isbn13} = useParams()

    const [lat, long, isLoading, locationName] = useCurrentLocation()
    const [librariesOffline, isLibraryOfflineLoading] = useBookLibraryOffline(isbn13 ?? '', lat, long)

    return (
        <BookSourceSection
            title={`도서관`}
            // @ts-ignore
            subTitle={locationName}
            // @ts-ignore
            body={
                isLibraryOfflineLoading ?
                    <></>
                    :
                    <Body librariesOffline={librariesOffline}/>
            }
        />
    )
}

interface BodyProps {
    librariesOffline: SearchBookSourceLibraryOfflineResponse[]
}

const Body: React.FC<BodyProps> = ({librariesOffline}) => {
    if (librariesOffline.length === 0) {
        return <BookSourceNoContent message={'도서관에는 책이 없어요'}/>
    }

    return (
        <>
            {
                librariesOffline.map(library => {
                    return (
                        <div className={'hover-effect'}>
                            <a href={library.link!!} target={'_blank'}>
                                <RowSpacer size={10}/>
                                <ContentContainer>
                                    <div>
                                        <span className={'d-flex'}>
                                            {
                                                library.library.logo &&
                                                <>
                                                    <Icon src={library.library.logo}/>
                                                    <ColSpacer size={5}/>
                                                </>
                                            }
                                            <h6 className={'p-0 m-0 pt-1'}>{library.library.name}</h6>
                                        </span>

                                        <RowSpacer size={5}/>

                                        <span className={'d-flex'}>
                                            <LoanPossibleBadge isLoanPossible={library.loanPossible}/>
                                            <ColSpacer size={10}/>
                                            <LoanPossibleBadge isLoanPossible={library.reservationPossible} possibleMessage={'예약가능'} impossibleMessage={'예약불가'}/>
                                        </span>
                                    </div>
                                </ContentContainer>
                            </a>
                        </div>
                    )
                })
            }
        </>
    )
}

const Icon = styled.img`
    width: 30px;
    height: 30px;
`

export default BookSourceOfflineLibrarySection
