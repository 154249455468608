import RouteContainer from '../../../common/styles/RouteContainer'
import RouteTitle from '../../../common/RouteTitle/RouteTitle'
import TipsList from './TipsList'
import RowSpacer from '../../../common/styles/RowSpacer'
import RouteTitleConfig from '../../../config/RouteTitleConfig'
import BooksitoutImages from "../../../images/BooksitoutImages";
import useHtmlTitle from "../../../common/useHtmlTitle";

const CommunityTipsRoute = () => {
	useHtmlTitle('꿀팁')

	return (
		<RouteContainer>
            <RouteTitle 
				icon={<img src={BooksitoutImages.WebsiteLogo.booksitout} alt='' style={{ width: '35px', height: '35px' }} />}
				title={'책잇아웃의 꿀팁'} 
				subTitle={'책에 관한 여러 꿀팁을 얻어 갈 수 있어요'} 
				currentKey={'tips'} 
				buttons={RouteTitleConfig.Community} 
				rightUi={undefined}
			/>

			<RowSpacer />
			<TipsList />
		</RouteContainer>
	)
}

export default CommunityTipsRoute