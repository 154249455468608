import React from 'react'
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import useUrlQuery from "../../../../common/hooks/useUrlQuery";
import BookSourceSection from "./common/BookSourceSection";
import useBookSubscription from "../hooks/useBookSubscription";
import RowSpacer from "../../../../common/styles/RowSpacer";
import ContentContainer from "../../../../common/styles/ContentContainer";
import {getBookSourceIcon, getBookSourceName} from "../BookSource";
import ColSpacer from "../../../../common/styles/ColSpacer";
import BookSourceNoContent from "../BookSourceNoContent";

const BookSourceSubscriptionSection = () => {
    const {isbn13} = useParams()
    const query = useUrlQuery('q')

    const [subscriptions, isSubscriptionLoading] = useBookSubscription(isbn13 ?? '', query ?? '')

    return (
        <BookSourceSection
            title={`구독 서비스`}
            body={
                isSubscriptionLoading ?
                    <></>
                    :
                    <Body subscriptions={subscriptions}/>
            }
        />
    )
}

interface BodyProps {
    subscriptions: BookSubscriptionResponse[]
}

const Body: React.FC<BodyProps> = ({subscriptions}) => {
    if (subscriptions.length === 0) {
        return <BookSourceNoContent message={'구독 서비스에는 책이 없어요'}/>
    }

    return (
        <>
            {
                subscriptions.map(subscription => {
                    return (
                        <div className={'hover-effect'}>
                            <a href={subscription.link!!} target={'_blank'}>
                                <RowSpacer size={10}/>
                                <ContentContainer>
                                    <div>
                                        <span className={'d-flex'}>
                                            <Icon src={getBookSourceIcon(subscription.provider)}/>
                                            <ColSpacer size={5}/>
                                            <h6 className={'p-0 m-0 pt-1'}>{getBookSourceName(subscription.provider)}</h6>
                                        </span>
                                    </div>
                                </ContentContainer>
                            </a>
                        </div>
                    )
                })
            }
        </>
    );
}

const Icon = styled.img.attrs({
    className: 'rounded'
})`
    width: 30px;
    height: 30px;
`

interface BookSubscriptionResponse {
	link: string
	provider: 'SUBSCRIPTION_KYOBO' | 'SUBSCRIPTION_RIDI' | 'SUBSCRIPTION_YES24' | 'SUBSCRIPTION_MILLIE'
}

export default BookSourceSubscriptionSection